exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cast-js": () => import("./../../../src/pages/cast.js" /* webpackChunkName: "component---src-pages-cast-js" */),
  "component---src-pages-competition-js": () => import("./../../../src/pages/competition.js" /* webpackChunkName: "component---src-pages-competition-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-holding-js": () => import("./../../../src/pages/holding.js" /* webpackChunkName: "component---src-pages-holding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tickets-js": () => import("./../../../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */),
  "component---src-pages-young-author-competition-js": () => import("./../../../src/pages/young-author-competition.js" /* webpackChunkName: "component---src-pages-young-author-competition-js" */)
}

